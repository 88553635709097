import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import MenuBar from './components/MenuBar.js';
import About from './components/About';
import Copyright from './components/Copyright';
import ArtPage from './components/ArtPage.js';
import styled from 'styled-components';

const MainContainer = styled.div`
  min-height: calc(100vh - 50px);
  width: 100%;
`;

const ART_PAGES = [
  {
    name: "game",
    route: "/gameart"
  },
  {
    name: "fine",
    route: "/fineart"
  },
  {
    name: "hidden",
    route: "/hiddenart"
  },
  {
    name: "gameartextras",
    route: "/gameartextras"
  },
  {
    name: "casinogames",
    route: "/casinogames"
  }
];

const App = () => {
  return (
    <>
      <Router>
        <MainContainer>
          <MenuBar />
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/gameart" />} />
            <Route exact path="/about">
              <About />
            </Route>
            {
              ART_PAGES.map((page) => (
                <Route exact path={page.route} key={page.name}>
                  <ArtPage pageName={page.name} />
                </Route>
              ))
            }
          </Switch>
        </MainContainer>
        <Copyright />
      </Router>
    </>
  );
};

export default App;
